import React from 'react';
import { Gallery } from "react-grid-gallery";

function PreviewContainer(props) {
    return (
        <div id="previewContainer">
            <Gallery images={props.imagePreviews} enableImageSelection={false} rowHeight={120} margin={5}/>
        </div>
    );
}

export default PreviewContainer;
