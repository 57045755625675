import React, { useRef, useState, useEffect } from 'react';
import jsQR from 'jsqr';
import './App.css';
import PreviewContainer from './PreviewContainer';
import Spinner from './Spinner';
import logo from './logo_cewe.png';
import './CEWEHead-Regular.woff2';

function App() {

  const socketAddr = 'wss://bdit.de/ws/';

  const [isSessionExpired, setSessionIsExpired] = useState(false);
  const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [overallProgressSize, setOverallProgressSize] = useState(0);
  const [overallProgressSent, setOverallProgressSent] = useState(0);

  const videoRef = useRef(null);
  const [scanning, setScanning] = useState(false);
  const [streamActive, setStreamActive] = useState(false);

  const inputRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  let dataChunks = [];
  const decoder = new TextDecoder('utf-8');

  const uploadImage = (socket, file, callback) => {

    const sendFileData = (socket, arrayBuffer, fileSize, callback) => {
      const blob = new Blob([arrayBuffer]);
      const CHUNK_SIZE = 65536;
      const sliceFile = offset => {
        if (offset < fileSize) {
          const chunk = blob.slice(offset, offset + CHUNK_SIZE);
          console.log("SENDING CHUNK", socket, chunk);
          socket.send(chunk);

          // Update overallProgressSent with the chunk size
          setOverallProgressSent(prevSent => prevSent + chunk.size);

          setTimeout(() => sliceFile(offset + CHUNK_SIZE), 0);
        } else {
          console.log("Sending <EOF>");
          socket.send('<EOF>'); // End-of-File
          setTimeout(callback, 0); // Proceed with the next file
        }
      };
      sliceFile(0);
    }

    const reader = new FileReader();
    reader.onload = event => sendFileData(socket, event.target.result, file.size, callback);
    reader.onerror = error => alert('FileReader error: ' + error.message);
    reader.readAsArrayBuffer(file);
  };

  const startUploadProcess = (socket, fileQueue) => {
    if (fileQueue.length === 0) {
      setOverallProgressSize(0);
      setOverallProgressSent(0);

      console.log("Sending <EOT>");
      socket.send('<EOT>'); // End-Of-Transmission
      return;
    }

    const file = fileQueue.shift(); // Get the first file from the queue
    uploadImage(socket, file, () => startUploadProcess(socket, fileQueue));
  };

  const startUpload = (socket) => {
    if (inputRef.current) {
      const files = inputRef.current.files;
      if (!files.length) {
        return;
      }
      let totalSize = getTotalSize(files);
      setOverallProgressSize(totalSize);
      setOverallProgressSent(0);

      //
      console.log("setOverallProgressSize: ", totalSize);
      //socket.send(totalSize.toString());

      startUploadProcess(socket, Array.from(files));
    }
  };

  function combineArrayBuffers(buffers) {
    let totalLength = buffers.reduce((acc, value) => acc + value.byteLength, 0);
    let combined = new Uint8Array(totalLength);
    let offset = 0;
    buffers.forEach(buffer => {
      combined.set(new Uint8Array(buffer), offset);
      offset += buffer.byteLength;
    });

    return combined.buffer;
  }

  function blobToDataURL(blob, callback) {
    const reader = new FileReader();
    reader.onload = function (e) {
      callback(e.target.result);
    };
    reader.readAsDataURL(blob);
  }

  useEffect(() => {
    // connectWebSocket();

    console.log("USE EFFECT!");

    const newSocket = new WebSocket(socketAddr);
    newSocket.binaryType = 'arraybuffer';

    newSocket.onopen = () => {
      console.log("SOCKET onopen");
      setIsWebSocketConnected(true);
      sendHello(newSocket, {});
    }
    newSocket.onclose = () => {
      console.log("SOCKET onclose");
      setIsWebSocketConnected(false);
    };
    newSocket.onerror = (error) => {
      console.log("SOCKET onerror");
      // console.log('WebSocket error:', error);
    };

    newSocket.onmessage = (event) => {
      console.log("SOCKET onmessage:", typeof event.data);
      let text = "";
      if ((typeof event.data) === "string") {
        text = event.data;
      } else if (event.data instanceof ArrayBuffer && event.data.byteLength < 512) {
        text = decoder.decode(event.data);
      }
      if (text.length > 0) {
        console.log("Got msg", text);
        if (text === '<FIN>') {
          console.log("Got <FIN>");
          inputRef.current.value = '';
          setImagePreviews([]);
        } else if (text === '<DL>') {
          console.log("Got <DL>");
          startUpload(newSocket);
        } else if (text === '<NAK>') {
          console.log("Got <NAK>");
          setSessionIsExpired(true);
          newSocket.close();
          const url = window.location.href.split('?')[0];
          window.history.pushState({ path: url }, '', url);
        } else if (text === '<PUSH>') {
          console.log("Got <PUSH>");
          dataChunks = [];
        } else if (text === '<EOF>') {
          console.log("Got <EOF>");
          const imageBuffer = combineArrayBuffers(dataChunks);
          dataChunks = [];
          const blob = new Blob([imageBuffer], { type: 'image/png' });
          setImageUrl(URL.createObjectURL(blob));
          newSocket.send('<FIN>');
        }
      } else {
        dataChunks.push(event.data);
      }
    };

    setSocket(newSocket);

    return () => { newSocket.close(); URL.revokeObjectURL(imageUrl); }
  }, []);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    setImageUrl("");
    const files = event.target.files;
    updatePreview(files);
    sendHello(socket, { "cnt": files.length, "size": getTotalSize(files) });
  }

  const updatePreview = (files) => {
    // Clear the current previews
    setImagePreviews([]);

    // Convert FileList to array
    const filesArray = Array.from(files);

    // Filter out non-image files and map to data URLs
    filesArray.filter(file => file.type.startsWith('image/'))
      .forEach(file => {
        const reader = new FileReader();
        reader.onload = e => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            console.log(`Image Width: ${img.width}, Image Height: ${img.height}`);
            setImagePreviews(prev => [...prev, { src: e.target.result, width: img.width, height: img.height }]);
          };
        };
        reader.readAsDataURL(file);
      });
  };

  const sendHello = (skt, additionalMsg) => {
    const msg = {
      "ver": 1,
      "agt": navigator.userAgent,
      "tgt": window.location.search.substring(1) || '',
      "lng": navigator.language || 'en_US',
      ...additionalMsg
    };
    console.log("Sending msg:", JSON.stringify(msg));
    skt.send(JSON.stringify(msg));
  }

  const getTotalSize = (files) => {
    return Array.from(files).reduce((total, file) => total + file.size, 0);
  }

  //  <button onClick={startUpload}>Upload Images</button>

  const startScan = () => {
    if (!streamActive) {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            setStreamActive(true);
          }
        }).catch(err => {
          console.error("Error accessing the camera:", err);
        });
    } else {
      setScanning(true);
    }
  };

  const onCanPlay = () => {
    setScanning(true);
    scanQRCode();
  };


  const scanQRCode = () => {
    //    if (!scanning) { return; }

    const video = videoRef.current;
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        console.log(`QR Code Detected: ${code.data}`);
        stopScan();
        window.location.href = code.data;
        return;
      }
    }

    requestAnimationFrame(scanQRCode);
  };

  const stopScan = () => {
    setScanning(false);
    setStreamActive(false);
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const handleImageDownload = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'alecollage.png';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const openImageInNewTab = () => {
    const newTab = window.open();
    newTab.document.body.style.backgroundColor = '#bebfc1';
    newTab.document.body.innerHTML = `
      <img src="${imageUrl}" style="max-width: 100%; height: auto; display: block; margin: 0 auto;">
      <p style="text-align: center; font-size: 2em;">Tap and hold the image, then choose 'Add to Photos' to save it.</p>
      <button onclick="window.close();" style="width: 50%; display: block; margin: 20px auto; padding: 40px; font-size: 50px; background-color: white; color: black; border: none; border-radius: 50px; cursor: pointer;">
      Go back
      </button>
      `;
  };

  return (
    <div className="App">
      <div className="header-bar">
        <div className="logo-container">
          <img src={logo} alt="CEWE" />
        </div>
        {isSessionExpired ? (
          <button onClick={startScan} disabled={scanning}>Scan QR code</button>
        ) : (
          <button onClick={handleButtonClick} disabled={isSessionExpired || (overallProgressSent > 0 && overallProgressSent < overallProgressSize)}>Add photos</button>
        )}
        <input type="file" id="imageInput" accept="image/*" multiple="multiple" ref={inputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      </div>

      <div className="container" id="container">
        {!isWebSocketConnected && !isSessionExpired ? (
          <Spinner />
        ) : isSessionExpired ? (
          <>
            <video ref={videoRef} onCanPlay={onCanPlay} muted playsInline autoPlay style={{ display: scanning ? 'block' : 'none' }} />
            {!scanning && (
              <div className="session-expired">
                <p className='attention'>Your session has expired. </p>
                <p>Please scan the QR code again.</p>
              </div>
            )}
          </>
        ) : imageUrl.length > 0 ? (
          <>
            <img src={imageUrl} style={{ maxWidth: '100%', height: 'auto' }} alt="ALE Image" />
            <button onClick={openImageInNewTab}>Save image</button>
          </>
        ) : overallProgressSent > 0 && overallProgressSent < overallProgressSize ? (
          <>
            <progress id="uploadProgress" value={overallProgressSent} max={overallProgressSize}></progress>
            <br />
          </>
        ) : imagePreviews.length > 0 ? (
          <PreviewContainer imagePreviews={imagePreviews} />
        ) : (
          <div className="no-photos-selected"><p>Your selected photos will automatically be transferred to the A.L.E.</p></div>
        )}
      </div>
    </div>
  );
}

export default App;
